import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useTheme } from '@material-ui/core/styles';


function ProjectCards(props) {
  const theme = useTheme();
  return (
    <Card className="project-card-view" style={{color:theme.palette.foreground.default}}>
      <Card.Img variant="top" src={props.imgPath} alt="card-img"  style={{maxHeight:'200px',padding:'5px', objectFit:'contain' }} />
      <Card.Body  style={{  }}>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text >
          {props.description}
        </Card.Text>
        {/* <Button variant="primary" href={props.ghLink} target="_blank"> */}
          {/* <BsGithub /> &nbsp; */}
          {/* {props.isBlog ? "Blog" : "GitHub"} */}
        {/* </Button> */}
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            {/* <CgWebsite /> &nbsp; */}
            {"Demo"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
